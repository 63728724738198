.react-loading-skeleton {
  border-radius: 0px;
  background-color: rgba(var(--color-element-light), 0.05);
  background-image: linear-gradient(
    90deg,
    rgba(var(--color-element-light), 0),
    rgba(var(--color-element-light), 0.05),
    rgba(var(--color-element-light), 0)
  );
  animation-duration: 2s;
}
