button > * {
  pointer-events: none;
}

.input-reset-search::-webkit-search-decoration,
.input-reset-search::-webkit-search-cancel-button,
.input-reset-search::-webkit-search-results-button,
.input-reset-search::-webkit-search-results-decoration {
  display: none;
}
