@variants responsive {
  .space-x-wrapper {
    @apply space-x-4;
  }

  .gap-x-wrapper {
    @apply gap-x-4;
  }

  .space-y-wrapper {
    @apply space-y-6;
  }

  .gap-y-wrapper {
    @apply gap-y-6;
  }

  .pt-wrapper {
    @apply pt-8;
  }

  .pb-wrapper {
    @apply pb-8;
  }

  .py-wrapper {
    @apply py-8;
  }

  .box-x-wrapper {
    @apply px-4;
  }

  @screen sm {
    .box-x-wrapper {
      @apply px-1/24;
    }
  }

  @screen md {
    .space-x-wrapper {
      @apply space-x-6;
    }

    .gap-x-wrapper {
      @apply gap-x-6;
    }

    .space-y-wrapper {
      @apply space-y-8;
    }

    .gap-y-wrapper {
      @apply gap-y-8;
    }

    .pt-wrapper {
      @apply pt-12;
    }

    .pb-wrapper {
      @apply pb-12;
    }

    .py-wrapper {
      @apply py-12;
    }

    .box-x-wrapper {
      @apply px-0 mx-auto w-full max-w-md;
    }
  }

  @screen lg {
    .space-x-wrapper {
      @apply space-x-10;
    }

    .gap-x-wrapper {
      @apply gap-x-10;
    }

    .space-y-wrapper {
      @apply space-y-12;
    }

    .gap-y-wrapper {
      @apply gap-y-12;
    }

    .pt-wrapper {
      @apply pt-14;
    }

    .py-wrapper {
      @apply py-14;
    }

    .box-x-wrapper {
      @apply max-w-lg;
    }
  }

  @screen xl {
    .space-x-wrapper {
      @apply space-x-12;
    }

    .gap-x-wrapper {
      @apply gap-x-12;
    }

    .box-x-wrapper {
      @apply max-w-xl;
    }
  }

  @screen max {
    .box-x-wrapper {
      @apply max-w-max;
    }
  }
}
