@variants responsive {
  .space-x-section {
    @apply space-x-4;
  }

  .gap-x-section {
    @apply gap-x-4;
  }

  .space-y-section {
    @apply space-y-4;
  }

  .gap-y-section {
    @apply gap-y-4;
  }

  @screen sm {
    .space-x-section {
      @apply space-x-4;
    }

    .gap-x-section {
      @apply gap-x-4;
    }

    .space-y-section {
      @apply space-y-6;
    }

    .gap-y-section {
      @apply gap-y-6;
    }
  }

  @screen md {
    .space-x-section {
      @apply space-x-6;
    }

    .gap-x-section {
      @apply gap-x-6;
    }

    .space-y-section {
      @apply space-y-6;
    }

    .gap-y-section {
      @apply gap-y-6;
    }
  }

  @screen lg {
    .space-x-section {
      @apply space-x-10;
    }

    .gap-x-section {
      @apply gap-x-10;
    }

    .space-y-section {
      @apply space-y-10;
    }

    .gap-y-section {
      @apply gap-y-10;
    }
  }
}
