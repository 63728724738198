@responsive {
  .fs-small-regular {
    @apply font-sans
      text-small
      font-medium
      leading-interface
      tracking-normal;
  }

  .fs-small-bold {
    @apply font-sans
      text-small
      font-semi-bold
      leading-interface
      tracking-wider;
  }

  .fs-small-link {
    @apply font-sans
      text-small
      font-semi-bold
      leading-interface
      tracking-wider
      underline;
  }

  .fs-base-interface-regular {
    @apply font-sans
      text-base
      font-normal
      leading-interface
      tracking-normal;
  }

  .fs-base-interface-bold {
    @apply font-sans
      text-base
      font-medium
      leading-interface
      tracking-wider;
  }

  .fs-base-interface-link {
    @apply font-sans
      text-base
      font-medium
      leading-interface
      tracking-wider
      underline;
  }

  .fs-base-paragraph-regular {
    @apply font-sans
      text-base
      font-normal
      leading-paragraph
      tracking-normal;
  }

  .fs-base-paragraph-bold {
    @apply font-sans
      text-base
      font-medium
      leading-paragraph
      tracking-wider;
  }

  .fs-base-paragraph-link {
    @apply font-sans
      text-base
      font-medium
      leading-paragraph
      tracking-wider
      underline;
  }

  .fs-large-regular {
    @apply font-sans
      text-large
      font-normal
      leading-semi-interface
      tracking-tight;
  }

  .fs-large-bold {
    @apply font-sans
      text-large
      font-medium
      leading-semi-interface
      tracking-wide;
  }

  .fs-large-link {
    @apply font-sans 
      text-large 
      font-medium 
      leading-semi-interface 
      tracking-wide 
      underline;
  }

  .fs-x-large-regular {
    @apply font-sans
      text-x-large
      font-normal
      leading-semi-interface
      tracking-tight;
  }

  .fs-x-large-bold {
    @apply font-sans
      text-x-large
      font-medium
      leading-semi-interface
      tracking-wide;
  }

  .fs-x-large-link {
    @apply font-sans
      text-x-large
      font-medium
      leading-semi-interface
      tracking-wide
      underline;
  }

  .fs-2x-large-regular {
    @apply font-sans
      text-2x-large
      font-normal
      leading-interface
      tracking-tight;
  }

  .fs-2x-large-bold {
    @apply font-sans
      text-2x-large
      font-medium
      leading-interface
      tracking-wide;
  }

  .fs-2x-large-link {
    @apply font-sans
      text-2x-large
      font-medium
      leading-interface
      tracking-wide
      underline;
  }

  .fs-3x-large-regular {
    @apply font-sans
      text-3x-large
      font-normal
      leading-interface
      tracking-tighter;
  }

  .fs-3x-large-bold {
    @apply font-sans
      text-3x-large
      font-medium
      leading-interface
      tracking-tighter;
  }

  .fs-3x-large-link {
    @apply font-sans
      text-3x-large
      font-medium
      leading-interface
      tracking-tighter
      underline;
  }

  .fs-4x-large-regular {
    @apply font-sans
      text-4x-large
      font-normal
      leading-interface
      tracking-tighter;
  }

  .fs-4x-large-bold {
    @apply font-sans
      text-4x-large
      font-medium
      leading-interface
      tracking-tighter;
  }

  .fs-4x-large-link {
    @apply font-sans
      text-4x-large
      font-medium
      leading-interface
      tracking-tighter
      underline;
  }
}

@layer base {
  /* latin-ext */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* latin-ext */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
}
